<template>
  <v-row
    no-gutters
    class="h-100"
  >
    <v-col cols="12" offset="0" md="6" offset-md="3" class="d-flex flex-column justify-center align-center">
      <v-card
        class="w-100 rounded-lg"
      >
        <v-card-title class="d-flex flex-column align-start">
          <div>
            {{ $t('twoFactorAuthentication.title') }}
          </div>
          <div
            v-if="phoneExist()"
            class="ml-1 subtitle-1"
          >
            {{ phoneTitle }}
          </div>
          <div
            v-if="emailExist()"
            class="ml-1 subtitle-1"
          >
            {{ emailTitle }}
          </div>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
            >
              <VerificationCodeInput
                v-model="code"
                :disabled="endTime || submitLoading"
              />
            </v-col>
            <v-col
              cols="12"
              class="text--secondary mb-12"
            >
              <span class="mr-2">{{ $t('twoFactorAuthentication.codeTimeValid') }}</span>
              <span>{{ codeLifetime }}</span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="px-4 mb-4">
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <v-btn color="info" outlined :disabled="submitLoading || generateNewCodeLoading" :loading="submitLoading || generateNewCodeLoading" @click="generateNewCode">
                {{ $t('twoFactorAuthentication.generateNewCode') }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              <div class="d-flex justify-end">
                <v-btn color="secondary" outlined :disabled="submitLoading || endTime" :loading="submitLoading" class="mr-2" @click="$router.push({ name: 'Login' })">
                  {{ $t('twoFactorAuthentication.cancel') }}
                </v-btn>
                <v-btn color="primary" :disabled="submitLoading || endTime" :loading="submitLoading" @click="submitHandle">
                  {{ $t('twoFactorAuthentication.save') }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-col>

    <ConfirmConsentDialog
      :value="confirmConsentDialog"
      :email="email"
      :password="password"
    />
  </v-row>
</template>

<script>
import services from '@/services'
import VerificationCodeInput from '@/components/VerificationCodeInput'
import moment from 'moment'
import { mapActions } from 'vuex'
import * as mutationTypes from '@/store/mutation-types'
import ConfirmConsentDialog from '@/components/dialogs/ConfirmConsentDialog'

export default {
  name: 'TwoFactorAuthentication',
  components: {
    VerificationCodeInput,
    ConfirmConsentDialog
  },
  data () {
    return {
      submitLoading: false,
      generateNewCodeLoading: false,
      endTime: false,
      code: '',
      codeLifetime: null,
      codeLifetimeInterval: null,
      confirmConsentDialog: false
    }
  },
  computed: {
    phoneTitle () {
      return `${this.$t('twoFactorAuthentication.phoneTitle')} ${sessionStorage.getItem('maskedPhone')}`
    },
    emailTitle () {
      return `${this.$t('twoFactorAuthentication.emailTitle')} ${sessionStorage.getItem('maskedEmail')}`
    },
    email () {
      return sessionStorage.getItem('email') || ''
    },
    password () {
      return sessionStorage.getItem('password') || ''
    }
  },
  created () {
    const codeDate = sessionStorage.getItem('twoFactorAuthExpiryDate')
    if (!codeDate) {
      return
    }
    this.codeLifetimeInterval = setInterval(this.calculateTime, 1000)
    this.calculateTime()
  },
  beforeDestroy () {
    if (this.codeLifetimeInterval) {
      clearInterval(this.codeLifetimeInterval)
    }

    sessionStorage.removeItem('email')
    sessionStorage.removeItem('password')
    sessionStorage.removeItem('maskedEmail')
    sessionStorage.removeItem('maskedPhone')
    sessionStorage.removeItem('twoFactorAuthExpiryDate')
  },
  methods: {
    ...mapActions({
      fetchUserData: 'fetchUserData'
    }),
    calculateTime () {
      const now = moment().valueOf()
      const expiryDate = moment(sessionStorage.getItem('twoFactorAuthExpiryDate')).valueOf()
      const remaining = expiryDate - now

      if (remaining <= 0) {
        this.codeLifetime = '00:00'
        this.endTime = true
        if (this.codeLifetimeInterval) {
          clearInterval(this.codeLifetimeInterval)
        }
        return
      }

      this.codeLifetime = moment(remaining).format('mm:ss')
    },
    async generateNewCode () {
      this.generateNewCodeLoading = true
      try {
        const { data } = await services.post('Authentication/RefreshTwoFactorCode', {
          email: sessionStorage.getItem('email'),
          oldCodeExpiry: sessionStorage.getItem('twoFactorAuthExpiryDate'),
          sendEmail: this.emailExist(),
          sendSms: this.phoneExist()
        })

        sessionStorage.setItem('twoFactorAuthExpiryDate', data)
        this.codeLifetimeInterval = setInterval(this.calculateTime, 1000)
        this.calculateTime()
        this.endTime = false
        this.code = ''

        await this.$store.dispatch(
          'setSuccessNotification',
          this.$t('twoFactorAuthentication.successfullyGeneratedNewCode')
        )
      } catch (e) {}
      this.generateNewCodeLoading = false
    },
    async submitHandle () {
      this.submitLoading = true
      try {
        const { data } = await services.post('Authentication/LoginTwoFactor', {
          email: sessionStorage.getItem('email'),
          authCode: this.code
        })

        if (data.token) {
          await this.$router.push({ name: 'AddDevice' })
          localStorage.setItem('token', data.token)
          await this.fetchUserData()
          this.$store.commit(mutationTypes.LOGIN, true)
        }

        if (!data.requiredAgreementsAccepted) {
          this.confirmConsentDialog = true
        }
      } catch (e) {}
      this.submitLoading = false
    },
    phoneExist () {
      return sessionStorage.getItem('maskedPhone').length > 0
    },
    emailExist () {
      return sessionStorage.getItem('maskedEmail').length > 0
    }
  }
}
</script>
