<template>
  <div class="verification-code-input w-full d-flex flex-column text-center">
    <div class="w-full d-flex justify-center mb-2">
        <template
          v-for="(numberInput, index) in numberInputs"
        >
          <v-text-field
            :key="index"
            v-model="numberInput.value"
            :autofocus="index === 0"
            :id="numberInput.id"
            :name="numberInput.id"
            :error="invalidCode || error"
            hide-details
            outlined
            dense
            single-line
            :class="{
              'mr-0': index === numberInputs.length - 1,
              'mr-2': index !== numberInputs.length - 1,
              'mr-10': index === 2
            }"
            :disabled="disabled"
            @input="inputHandle($event, index, numberInput)"
          />
        </template>
    </div>
    <small v-if="invalidCode" class="red--text mt-2">{{ this.$t('validation.confirmCodeInvalid') }}</small>
  </div>
</template>

<script>
export default {
  name: 'VerificationCodeInput',
  props: {
    value: {
      type: String,
      default: ''
    },
    error: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      numberInputs: [
        {
          id: 'numberInput0',
          value: null,
          touched: false
        },
        {
          id: 'numberInput1',
          value: null,
          touched: false
        },
        {
          id: 'numberInput2',
          value: null,
          touched: false
        },
        {
          id: 'numberInput3',
          value: null,
          touched: false
        },
        {
          id: 'numberInput4',
          value: null,
          touched: false
        },
        {
          id: 'numberInput5',
          value: null,
          touched: false
        }
      ]
    }
  },
  computed: {
    allNumberInputsTouched () {
      const inputsTouched = this.numberInputs.filter((input) => input.touched)
      return inputsTouched.length === this.numberInputs.length
    },
    isValid () {
      const inputsHasValue = this.numberInputs.filter((input) => {
        if (input.value) {
          return input.value.toString().length
        }
      })
      return inputsHasValue.length === this.numberInputs.length
    },
    invalidCode () {
      return this.allNumberInputsTouched && !this.isValid
    },
    codeValue () {
      return this.numberInputs
        .map((input) => (input.value))
        .join('')
    }
  },
  watch: {
    value (val) {
      if (!val) {
        this.clearCode()
      }
    },
    codeValue () {
      this.$emit('input', this.codeValue)
    }
  },
  methods: {
    inputHandle (event, id, data) {
      data.touched = true
      if (!event.length) {
        return
      }
      const nextInputFocusId = `numberInput${id + 1}`
      const numberInput = document.getElementById(nextInputFocusId)
      if (numberInput) {
        numberInput.focus()
      } else {
        const currentInputId = `numberInput${id}`
        const currentInput = document.getElementById(currentInputId)
        if (!currentInput) {
          return
        }
        currentInput.blur()
      }
    },
    clearCode () {
      this.numberInputs = this.numberInputs.map(input => {
        return { ...input, value: null, touched: false }
      })
    }
  }
}
</script>

<style lang="scss">
.verification-code-input {
  .v-input {
    max-width: 45px;
    max-height: 60px;
  }

  input {
    text-align: center;
    font-size: 200%;
    font-weight: bold;
  }
}
</style>
